.subHeaderPreview{width: $full-width;float: left;background-color: #121f29;@include flexCenter();height: 36px;border-radius: 5px;}
.subHeaderPreviewInner{width: 90%;float: left;display: flex;justify-content: space-between;flex-direction: row;}

.subHeaderPreviewLeftLinks{float: left;display: flex;flex-direction: row;gap: 20px;}
.subHeaderPreviewLeftLink{float: left;display: flex;flex-direction: row;align-items: center;gap: 10px;border-right: 1px solid $white;padding-right: 20px;}
.subHeaderPreviewLeftLink:last-child{padding: 0px;border: 0px;}
.subHeaderPreviewLeftLinkIcon{float: left;display: flex;align-items: center;}
.subHeaderPreviewLeftLinkIco{float: left;color: $white;font-size: 0.9em;}
.subHeaderPreviewLeftLinkImg{max-height: 18px;float: left;}
.subHeaderPreviewLeftLinkLable{float: left;}
.subHeaderPreviewLeftLinkLableTxt{float: left;color: $white;text-transform: uppercase;font-size: 0.75em;}

.subHeaderPreviewRightLinks{float: left;display: flex;flex-direction: row;gap: 10px;}
.subHeaderPreviewRightLink{float: left;display: flex;flex-direction: row;align-items: center;gap: 10px;}
.subHeaderPreviewRightLinkThemeSelection{float: left;}
.subHeaderPreviewRightLinkThemeSelectionInner{height: 24px;float: left;display: flex;flex-direction: row;border: 3px solid $white;border-radius: 30px;overflow: hidden;}
.subHeaderPreviewRightLinkThemeSelectionSingle{width: 25px;height: 20px;float: left;@include flexCenter();padding: 0px 15px;}
.subHeaderPreviewRightLinkThemeSelectionSingle.dark{background-color: #121f29;}
.subHeaderPreviewRightLinkThemeSelectionSingle.light{background-color: #e8e005;}
.subHeaderPreviewRightLinkThemeSelectionSingleIco{color: #fff;font-size: 0.7em;}
.subHeaderPreviewRightLinkFontSizeSelection{float: left;}
.subHeaderPreviewRightLinkFontSizeSelectionInner{height: 24px;float: left;display: flex;flex-direction: row;border-radius: 30px;overflow: hidden;background-color: $white;}
.subHeaderPreviewRightLinkFontSizeSelectionSingle{float: left;padding: 0px 10px;border-right: 1px solid #121f29;display: flex;align-items: center;}
.subHeaderPreviewRightLinkFontSizeSelectionSingle:last-child{border: 0px;}
.subHeaderPreviewRightLinkFontSizeSelectionSingleIco{float: left;font-size: 0.7em;}
.subHeaderPreviewRightLinkFontSizeSelectionSingleTxt{float: left;font-size: 0.8em;font-family: font("semibold");}
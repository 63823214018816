@font-face {
    font-family: "Poppins Thin";
    src: local("Poppins-Thin"),
    url("../../public/fonts/poppins/Poppins-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins Light";
    src: local("Poppins-Light"),
    url("../../public/fonts/poppins/Poppins-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins ExtraLight";
    src: local("Poppins-ExtraLight"),
    url("../../public/fonts/poppins/Poppins-ExtraLight.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins Regular";
    src: local("Poppins-Regular"),
    url("../../public/fonts/poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins Medium";
    src: local("Poppins-Medium"),
    url("../../public/fonts/poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins SemiBold";
    src: local("Poppins-SemiBold"),
    url("../../public/fonts/poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins Bold";
    src: local("Poppins-Bold"),
    url("../../public/fonts/poppins/Poppins-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins ExtraBold";
    src: local("Poppins-ExtraBold"),
    url("../../public/fonts/poppins/Poppins-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "Poppins Black";
    src: local("Poppins-Black"),
    url("../../public/fonts/poppins/Poppins-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Inter Thin";
    src: local("Inter-Thin"),
    url("../../public/fonts/inter/Inter-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "Inter Light";
    src: local("Inter-Light"),
    url("../../public/fonts/inter/Inter-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Inter ExtraLight";
    src: local("Inter-ExtraLight"),
    url("../../public/fonts/inter/Inter-ExtraLight.ttf") format("truetype");
}
@font-face {
    font-family: "Inter Regular";
    src: local("Inter-Regular"),
    url("../../public/fonts/inter/Inter-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Inter Medium";
    src: local("Inter-Medium"),
    url("../../public/fonts/inter/Inter-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Inter SemiBold";
    src: local("Inter-SemiBold"),
    url("../../public/fonts/inter/Inter-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Inter Bold";
    src: local("Inter-Bold"),
    url("../../public/fonts/inter/Inter-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Inter ExtraBold";
    src: local("Inter-ExtraBold"),
    url("../../public/fonts/inter/Inter-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "Inter Black";
    src: local("Inter-Black"),
    url("../../public/fonts/inter/Inter-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Nato Sans Kannada Thin";
    src: local("Nato-Sans-Kannada-Thin"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada Light";
    src: local("Nato-Sans-Kannada-Light"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-Light.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada ExtraLight";
    src: local("Nato-Sans-Kannada-ExtraLight"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-ExtraLight.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada Medium";
    src: local("Nato-Sans-Kannada-Medium"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada Regular";
    src: local("Nato-Sans-Kannada-Regular"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada SemiBold";
    src: local("Nato-Sans-Kannada-SemiBold"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-SemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada Bold";
    src: local("Nato-Sans-Kannada-Bold"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada ExtraBold";
    src: local("Nato-Sans-Kannada-ExtraBold"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "Nato Sans Kannada Black";
    src: local("Nato-Sans-Kannada-Black"),
    url("../../public/fonts/nato-sans-kannada/NotoSerifKannada-Black.ttf") format("truetype");
}
/* width */
::-webkit-scrollbar {
    width: 5px;
    position: absolute;
    right: 0px;
    top: 0px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: none; 
    opacity: 0;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $text-description;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $disabledTxt; 
  }